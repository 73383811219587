<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>{{ RouteLabel.Home }}</h1>

<pf-alert
  *ngIf="!hasRoles"
  label="Vous n'avez pas de rôles définis. Veuillez vous rapprocher d'un administrateur."
  severity="warn"
></pf-alert>

@if (hasPermissionToQuestionActions) {
  <h5>Actions en cours</h5>
  <p-tabView>
    <p-tabPanel [header]="waitingValidationQuestionsTitle">
      <app-home-questions-list
        [columns]="waitingValidationQuestionsColumns"
        [questions]="waitingValidationQuestions"
        noResultLabel="Pas de question en attente de validation"
        (parametersChangedHandler)="
          waitingValidationQuestionParameterChangedHandler($event)
        "
      >
        <ng-template #customColumn let-question>
          <td>{{ question.lastModifiedDate | date: "dd/MM/yyyy" }}</td>

          <td class="icon-button">
            <div class="flex gap-2">
            <p-button
              icon="pi pi-search"
              [rounded]="true"
              ariaLabel="Analyser"
              tooltipPosition="top"
              pTooltip="Analyser"
              [routerLink]="getAnalyzedQuestionIdRoute(question.id)"
            ></p-button>
              <p-button
                icon="pi pi-file-edit"
                [rounded]="true"
                ariaLabel="Editer"
                tooltipPosition="top"
                pTooltip="Editer"
                [routerLink]="getQuestionIdRoute(question.id)"
              ></p-button>
            </div>
          </td>
        </ng-template>
      </app-home-questions-list>
    </p-tabPanel>
    <p-tabPanel [header]="draftQuestionsTitle">
      <app-home-questions-list
        [columns]="draftQuestionsColumns"
        [questions]="draftQuestions"
        noResultLabel="Pas de question en brouillon"
        (parametersChangedHandler)="
          draftQuestionParameterChangedHandler($event)
        "
      >
        <ng-template #customColumn let-question>
          <td>{{ question.lastModifiedDate | date: "dd/MM/yyyy" }}</td>

          <td class="icon-button">
            <p-button
              icon="pi pi-file-edit"
              [rounded]="true"
              ariaLabel="Editer"
              tooltipPosition="top"
              pTooltip="Editer"
              [routerLink]="getQuestionIdRoute(question.id)"
            ></p-button>
          </td>
        </ng-template>
      </app-home-questions-list>
    </p-tabPanel>
    <p-tabPanel [header]="rejectedQuestionsTitle">
      <app-home-questions-list
        [columns]="rejectedQuestionsColumns"
        [questions]="rejectedQuestions"
        noResultLabel="Pas de question en rejetée"
        (parametersChangedHandler)="
          rejectedQuestionParameterChangedHandler($event)
        "
      >
        <ng-template #customColumn let-question>
          <td>
            {{ question.lastModifiedDate | date: "dd/MM/yyyy" }}
          </td>

          <td class="icon-button">
            <p-button
              icon="pi pi-file-edit"
              [rounded]="true"
              ariaLabel="Editer"
              tooltipPosition="top"
              pTooltip="Editer"
              [routerLink]="getQuestionIdRoute(question.id)"
            ></p-button>
          </td>
        </ng-template>
      </app-home-questions-list>
    </p-tabPanel>
  </p-tabView>
}

@if (hasPermissionToManageSession) {
  <div class="grid">
    <app-home-exam-sessions class="col-4">

    </app-home-exam-sessions>
  </div>
}
