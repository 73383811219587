import { Authority } from '@models/user/authorities.model';

export type RoleDetail = {
  id: string;
  name: string;
  displayName: string;
  roleAuthorities: Authority[];
};

export const EXTERNAL_SUPERVISOR = 'EXTERNAL_SUPERVISOR';
export const INTERNAL_SUPERVISOR = 'INTERNAL_SUPERVISOR';
export const ADMIN_TENANT = 'ADMIN_TENANT';
export const QUESTION_MANAGER = 'QUESTION_MANAGER';
export const SERIES_MANAGER = 'SERIES_MANAGER';
